.Wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 1rem;
}
.NotificationsContainer {
  border-radius: 0.5em;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  max-height: 100%;
  border: 1px solid;
  border-radius: 5px;
}
.TabButtonDiv {
  display: flex;
  justify-content: space-between;
}
.Tables {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  padding-bottom: 1rem;
}
.ScrollParent {
  scroll-snap-type: y mandatory !important;
}
.AdminFilterDiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}
.SendNotificationButton {
  width: 7.5em;
  align-self: flex-end;
  margin-top: 7px;
}
