.EmptySet {
  margin-top: 1.25rem;
  text-align: center;
}

.WaypointContainer {
  display: flex;
  justify-content: center;
}

.SpaceContainer {
  height: 1rem;
}
.IconDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.Status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 30px;
  border-radius: 5px;
  padding: 4px 4px;
}
.ExpandMoreIcon {
  cursor: pointer;
  pointer-events: none;
  background-color: transparent;
  border: none;
  height: 24px;
  width: 24px;
  margin: -5px;
}
.NotificationTitle {
  display: flex;
  align-items: center;
  max-width: 220px;
  flex-grow: 1;
  flex-basis: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ColumnTitle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.TitleText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
