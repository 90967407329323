.EmptySet {
  margin-top: 1.25rem;
  text-align: center;
}

.WaypointContainer {
  display: flex;
  justify-content: center;
}

.SpaceContainer {
  height: 1rem;
}
.IconDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.tables {
  padding: 8px;
}
